import React from 'react';
import { flatten, get, head, take, isEmpty, pickBy } from 'lodash';
import { Typography } from '@pitchero/react-ui';
import Icon from '../../icon';

const TEAMS_PER_SECTION_LIMIT = 6;

function formatTeam(team) {
  return {
    ...team,
    id: team.id,
    routeParams: { route: 'team.homepage', params: { teamId: team.id } },
  };
}

// Add routing params to each team and section,
// Limit each section if there are more than 'TEAMS_PER_SECTION_LIMIT'
// Add View all option if this is the case.
function formatTeamsSections(sections, limitPerSection) {
  function formatTeams(teams, sectionId) {
    const formattedTeams = teams.map(formatTeam);

    if (limitPerSection && formattedTeams.length > TEAMS_PER_SECTION_LIMIT) {
      const limitedTeams = take(formattedTeams, TEAMS_PER_SECTION_LIMIT);
      limitedTeams.push({
        name: (
          <Typography preset="subtitle--small" color="primary">
            View All
            <Icon name="arrow-right" size={10} />
          </Typography>
        ),
        id: 'viewAll',
        routeParams: { route: 'team.list', hash: sectionId },
      });

      return limitedTeams;
    }

    return formattedTeams;
  }

  return sections.map((section) => ({
    ...section,
    id: section.id,
    options: formatTeams(section.teams, section.id),
  }));
}

function getTeamsOptions(sections, limitTeams = false) {
  if (isEmpty(sections)) {
    return {};
  }
  const teams = flatten(sections.map((section) => section.teams));
  const hasMultipleTeams = teams.length > 1;
  if (!hasMultipleTeams) {
    const team = teams[0];
    return {
      transKey: 'common:team',
      routeParams: { route: 'team.homepage', params: { teamId: team.id } },
    };
  }

  if (sections.length === 1) {
    const onlySection = head(sections);
    return {
      transKey: 'common:teams',
      routeParams: { route: 'team.list' },
      options: onlySection.teams.map(formatTeam),
    };
  }

  const singleTeamSections = sections.filter((section) => section.teams.length > 1).length === 0;
  if (singleTeamSections) {
    const allTeams = sections.map((section) => head(section.teams));
    return {
      transKey: 'common:teams',
      routeParams: { route: 'team.list' },
      options: allTeams.map(formatTeam),
    };
  }

  return {
    transKey: 'common:teams',
    routeParams: { route: 'team.list' },
    groupedOptions: formatTeamsSections(sections, limitTeams),
  };
}

// Create navigation items object, including conditionally showing payments
// and formatting the teams and their sections.
export default function getNavItems({ club, limitTeams = false }) {
  const { sections, galleryEnabled, paymentsEnabled, facilityBookingsEnabled } = club;

  const items = {
    home: {
      id: 'home',
      transKey: 'common:home',
      getIsActive: (activeSection) => activeSection === 'home',
      routeParams: { route: 'homepage' },
      canDisplay: true,
    },
    matches: {
      id: 'matches',
      transKey: 'common:matches',
      getIsActive: (activeSection) => activeSection === 'matches',
      routeParams: { route: 'matches' },
      canDisplay: true,
    },
    teams: {
      id: 'teams',
      getIsActive: (activeSection) => activeSection === 'teams',
      icon: 'USER_CIRCLE',
      ...getTeamsOptions(sections, limitTeams),
      canDisplay: !isEmpty(sections),
    },
    news: {
      id: 'news',
      transKey: 'common:news',
      getIsActive: (activeSection) => activeSection === 'news',
      routeParams: { route: 'news' },
      icon: 'PAGE',
      canDisplay: true,
    },
    shop: {
      id: 'shop',
      transKey: 'common:shop',
      getIsActive: (activeSection) => activeSection === 'shop',
      routeParams: { route: 'shop' },
      icon: 'TROLLEY',
      canDisplay: paymentsEnabled,
    },
    gallery: {
      id: 'gallery',
      transKey: 'common:gallery',
      getIsActive: (activeSection) => activeSection === 'albums' || activeSection === 'videos',
      routeParams: { route: 'media.albums' },
      icon: 'ALBUM',
      canDisplay: galleryEnabled,
    },
    contact: {
      id: 'contact',
      transKey: 'common:contact',
      getIsActive: (activeSection) => activeSection === 'contact',
      routeParams: { route: 'contact' },
      icon: 'PHONE',
      canDisplay: get(club, 'hasOfficialsOrValidAddress', true),
    },
    bookings: {
      id: 'bookings',
      transKey: 'common:bookings',
      getIsActive: (activeSection) => activeSection === 'bookings',
      routeParams: { route: 'bookings', forceReload: true },
      icon: 'BOOKTEQ',
      canDisplay: facilityBookingsEnabled,
    },
    information: {
      id: 'information',
      transKey: 'common:information',
      getIsActive: (activeSection) => activeSection === 'information',
      routeParams: { route: 'information.index' },
      icon: 'INFO_CIRCLE_OUTLINE',
      canDisplay: true,
    },
  };

  return pickBy(items, (item) => item.canDisplay);
}

export const getFooterNavItems = (club) => {
  const items = getNavItems({ club });
  const { hasPolicies } = club;

  if (hasPolicies) {
    items.policies = {
      id: 'policies',
      transKey: 'common:policies',
      getIsActive: () => false,
      routeParams: { route: 'information.policies' },
    };
  }

  return items;
};
