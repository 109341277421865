import ApiRequestException from '../../exceptions/api-request-exception';
import {
  LOGIN_SUCCESS,
  NETWORK_PANEL_REQUEST,
  NETWORK_PANEL_RECEIVE,
  NETWORK_PANEL_FAILURE,
  NETWORK_PANEL_REQUEST_SUCCESS,
  NETWORK_PANEL_OPEN,
  NETWORK_PANEL_CLOSE,
  MEMBERSHIP_APPLY_CLUB_MEMBER_REQUEST,
  MEMBERSHIP_APPLY_CLUB_MEMBER_RECEIVE,
  MEMBERSHIP_APPLY_CLUB_MEMBER_FAILURE,
  UNAUTHORISED_RESPONSE,
} from '../action-types';

export const fetchNetworkPanelData = () => async (dispatch) => {
  try {
    dispatch({ type: NETWORK_PANEL_REQUEST });
    const { makeIsomorphicApiRequest } = await import('../../lib/api-proxy');
    const response = await makeIsomorphicApiRequest('/v2/users/network-panel');

    if (response.status !== 200) {
      if (response.status === 401 || response.status === 403) {
        dispatch({ type: UNAUTHORISED_RESPONSE });
      }

      dispatch({
        type: NETWORK_PANEL_FAILURE,
        payload: { error: new ApiRequestException('Error getting membership', response.status) },
      });
    } else {
      const data = await response.json();
      dispatch({ type: LOGIN_SUCCESS });
      dispatch({ type: NETWORK_PANEL_RECEIVE, payload: data });
      dispatch({ type: NETWORK_PANEL_REQUEST_SUCCESS });
    }
  } catch (error) {
    dispatch({ type: NETWORK_PANEL_FAILURE, payload: { error } });
  }
};

export const submitBecomeClubMember = (clubId, memberId) => async (dispatch) => {
  try {
    dispatch({ type: MEMBERSHIP_APPLY_CLUB_MEMBER_REQUEST, payload: { clubId, memberId } });
    const { makeIsomorphicApiRequest } = await import('../../lib/api-proxy');
    const response = await makeIsomorphicApiRequest(
      `/v2/clubs/${clubId}/member-applications${memberId ? `?acting_as=${memberId}` : ''}`,
      {
        method: 'POST',
      },
    );
    if (response.status !== 201) {
      dispatch({
        type: MEMBERSHIP_APPLY_CLUB_MEMBER_FAILURE,
        clubId,
        payload: new ApiRequestException('Error making application', response.status),
      });
    } else {
      const { application_id: applicationId } = await response.json();
      dispatch({
        type: MEMBERSHIP_APPLY_CLUB_MEMBER_RECEIVE,
        payload: { applicationId, clubId, memberId },
      });
    }
  } catch (error) {
    dispatch({ type: MEMBERSHIP_APPLY_CLUB_MEMBER_FAILURE, clubId, payload: error });
  }
};

export const openNetworkPanel = () => (dispatch) => {
  dispatch({ type: NETWORK_PANEL_OPEN });
};

export const closeNetworkPanel = () => (dispatch) => {
  dispatch({ type: NETWORK_PANEL_CLOSE });
};
