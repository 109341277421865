import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  AbsoluteContainer,
  Avatar,
  Cushion,
  FlexContainer,
  RelativeContainer,
  ShimmerAvatar,
  Space,
  Typography,
} from '@pitchero/react-ui';
import Icon from '../../../icon';
import NotificationBadge from '../../../notification-badge';
import { getLoggedInUser, getTotalNotificationCount } from '../../../../store/membership/selectors';
import { openNetworkPanel as openNetworkPanelAction } from '../../../../store/membership/actions';
import { Trans } from '../../../../lib/i18n';

const AccountLink = ({ isLoading, notificationCount, openNetworkPanel, user, onClose }) => {
  if (isLoading) {
    return <ShimmerAvatar size={32} />;
  }

  if (!user) {
    return null;
  }

  const { avatar, firstName, name } = user;
  return (
    <FlexContainer
      alignItems="center"
      role="button"
      tabIndex={-1}
      aria-label="Account"
      onClick={() => {
        onClose();
        openNetworkPanel();
      }}
      onKeyDown={openNetworkPanel}
      style={{ cursor: 'pointer' }}
    >
      <Space top="xxsmall">
        <RelativeContainer style={{ width: 'auto' }}>
          <Avatar image={avatar} includeRetina alt={name} size={32} />
          <AbsoluteContainer top={-6} right={-6}>
            <NotificationBadge total={notificationCount} />
          </AbsoluteContainer>
        </RelativeContainer>
      </Space>
      <Cushion left="small" style={{ flex: 1 }}>
        <Typography preset="tag--large" color="white" component="div">
          <Trans i18nKey="common:network_panel_welcome" values={{ name: firstName }}>
            {`Hi ${firstName}`}
          </Trans>
        </Typography>
        <Typography preset="subtitle--small" color="white" component="div">
          <Trans i18nKey="common:network_panel_account_link">Your account</Trans>
          <Cushion left="xxsmall">
            <Icon name="arrow-right" size={12} topOffset={2} />
          </Cushion>
        </Typography>
      </Cushion>
    </FlexContainer>
  );
};

AccountLink.defaultProps = {
  user: null,
};

AccountLink.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  notificationCount: PropTypes.number.isRequired,
  openNetworkPanel: PropTypes.func.isRequired,
  user: PropTypes.shape({
    avatar: PropTypes.string,
    firstName: PropTypes.string,
    name: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
};

const dispatchToProps = {
  openNetworkPanel: openNetworkPanelAction,
};

const mapStateToProps = (state) => ({
  notificationCount: getTotalNotificationCount(state),
  user: getLoggedInUser(state),
});

export default connect(mapStateToProps, dispatchToProps)(AccountLink);
