import { useTheme } from '@pitchero/react-ui';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Embed an external .svg image and apply a color to it using CSS
 */
const ColoredSvg = ({ alt, background, height, preset, priority, src, style, width }) => {
  const { uiTheme } = useTheme();

  // Accepting actual CSS colour values, as well as the names of colours in the
  // theme, as well as the names of "presets" is complicated, but this is
  // included as this is a drop-in replacement for the `Icon` component at
  // https://github.com/Pitchero/react-ui/blob/feb74b47aeef92e49d0356d8bf58a32753f6afbf/src/components/Icons/template/index.jsx
  // and we want to maintain compatibility with that.
  const colorValue = uiTheme?.colors?.[background];
  const presetData = uiTheme?.icon?.presets?.[preset];
  const finalWidth = presetData ? presetData.size : width;
  const finalHeight = presetData ? presetData.size : height;
  const presetStyle = presetData ? { top: presetData.topOffset } : {};

  const isUrgent = priority === 'high';

  // You normally can't style an .svg file when it is loaded from a URL into an
  // `<img>` tag. This technique let's us do that by loading the image as a
  // *shape* by way of a mask, then coloring that shape with CSS.
  //
  // Because `background` is used to colour the shape, it can be a solid colour
  // or a gradient.
  //
  // Multiple colours are not supported, such as a player substitution icon with
  // a red arrow and a green arrow. For icons like that we are better just
  // inlining the SVG, or putting the colours in the original SVG file.
  //
  // See https://codepen.io/noahblon/post/coloring-svgs-in-css-background-images

  return (
    // We could use a `<div>` instead of an `<img>` as all we need is a single
    // element to style, but for accessibility we use an `<img>` tag – it *is*
    // an image, so should be read out to screen readers as such.
    <>
      {isUrgent && (
        <Head>
          <link
            rel="preload"
            as="image"
            type="image/svg+xml"
            // Fix: "Warning: React does not recognize the `fetchPriority` prop
            // on a DOM element. If you intentionally want it to appear in the
            // DOM as a custom attribute, spell it as lowercase `fetchpriority`
            // instead. If you accidentally passed it from a parent component,
            // remove it from the DOM element."
            // eslint-disable-next-line react/no-unknown-property
            fetchpriority="high"
            href={src}
            key={`preload-${src}`}
            crossOrigin="anonymous"
          />
        </Head>
      )}
      <img
        // Because it's an image where we don't need the actual image in the
        // foreground, we use a 1x1 transparent spacer image as the `src`. It
        // seems like a hack, but I think using the correct HTML element is
        // important, again, for accessibility.
        src="https://img-res.pitchero.com/?url=images.pitchero.com%2Fup%2Fspacer.gif"
        alt={alt}
        style={{
          ...presetStyle,
          ...style,
          // we could get given an actual CSS colour value or a name of one from a
          // theme (which may or may not match anything), or nothing, so we need
          // to default to a colour.
          background: colorValue || background || '#333',
          display: 'inline-block',
          // load the external SVG as a shape only
          mask: `url(${src}) no-repeat 50% 50%`,
          // make the mask shape fill the size of this image element
          maskSize: 'cover',
          // these two properties were set in the original `Icon` component so
          // have been kept to avoid changes to the layout
          position: 'relative',
          verticalAlign: 'baseline',
          // doubly make sure the icon dimensions are correct
          width: finalWidth,
          height: finalHeight,
        }}
        // avoid layout shifts by setting dimensions of the icon (not the spacer)
        width={finalWidth}
        height={finalHeight}
      />
    </>
  );
};

ColoredSvg.defaultProps = {
  background: '',
  preset: '',
  priority: 'auto',
  style: {},
};

ColoredSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  background: PropTypes.string,
  height: PropTypes.number.isRequired,
  preset: PropTypes.string,
  priority: PropTypes.oneOf(['high', 'auto', 'low']),
  src: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  width: PropTypes.number.isRequired,
};

export default ColoredSvg;
