import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../icon';

const rankGroups = {
  bronze: ['#FFA37C', '#C9440B'],
  silver: ['#FFF', '#B4B4B4'],
  gold: ['#FFE291', '#FFBD00'],
  default: ['#FFF', '#FFF'],
};

const RankRibbon = ({ rankGroup, size }) => {
  const [from, to] = rankGroups[rankGroup] || rankGroups.default;
  return (
    <Icon
      alt={rankGroup || ''}
      color={`linear-gradient(${from}, ${to})`}
      name="ribbon"
      size={size}
    />
  );
};

RankRibbon.propTypes = {
  rankGroup: PropTypes.string.isRequired,
  size: PropTypes.number,
};

RankRibbon.defaultProps = {
  size: 16,
};

export default RankRibbon;
