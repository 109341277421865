import PropTypes from 'prop-types';
import React from 'react';
import ColoredSvg from './colored-svg';

/**
 * Embed a named icon as an external .svg image and apply a color to
 * it using CSS
 */
const Icon = ({
  alt,
  color,
  name,
  preset,
  priority,
  size,
  style,
  topOffset,
  // Stop this prop, passed in by HOCs, from getting further
  // eslint-disable-next-line react/prop-types
  uiTheme: _ignore,
  ...otherProps
}) => {
  const image = (
    <ColoredSvg
      alt={alt}
      background={color}
      height={size}
      preset={preset}
      priority={priority}
      src={`/static/images/icons/${name}.svg`}
      style={topOffset ? { ...style, top: topOffset } : style}
      width={size}
    />
  );
  return otherProps ? (
    <span
      {...otherProps}
      height={size}
      width={size}
      style={{
        display: 'inline-block',
        lineHeight: 0,
      }}
    >
      {image}
    </span>
  ) : (
    image
  );
};

Icon.defaultProps = {
  alt: '',
  color: '',
  preset: '',
  priority: 'auto',
  size: 16,
  style: {},
  topOffset: 0,
};

Icon.propTypes = {
  alt: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  preset: PropTypes.string,
  priority: PropTypes.oneOf(['high', 'auto', 'low']),
  size: PropTypes.number,
  style: PropTypes.shape(),
  topOffset: PropTypes.number,
};

export default Icon;
