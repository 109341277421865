import React from 'react';
import PropTypes from 'prop-types';
import ColoredSvg from '../icon/colored-svg';

const hwRatio = 24 / 116;

const PitcheroLogo = ({ width, color }) => (
  <ColoredSvg
    alt="Pitchero"
    background={color}
    height={Number.isFinite(width) ? width * hwRatio : 'auto'}
    src="/static/images/pitchero-logo.svg"
    width={width}
  />
);

PitcheroLogo.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

PitcheroLogo.defaultProps = {
  width: 116,
  color: '#FFF',
};

export default PitcheroLogo;
