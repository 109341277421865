import { Cushion, FlexContainer, Space } from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../icon';

const SocialLinks = ({ facebookPage, twitterAccount, instagramAccount }) => (
  <Cushion left="medium" top="medium" bottom="xlarge">
    <FlexContainer alignItems="center">
      {facebookPage && (
        <Space right="medium">
          <a href={facebookPage} title="facebook" aria-label="Club Facebook">
            <Icon name="facebook" color="white" size={24} />
          </a>
        </Space>
      )}
      {twitterAccount && (
        <Space right="medium">
          <a
            href={`https://twitter.com/${twitterAccount}`}
            title="twitter"
            aria-label="Club Twitter"
          >
            <Icon name="twitter" color="white" size={20} viewBox="0 0 1200 1200" />
          </a>
        </Space>
      )}
      {instagramAccount && (
        <Space right="medium">
          <a href={instagramAccount} title="instagram" aria-label="Club Instagram">
            <Icon name="instagram" color="white" size={24} />
          </a>
        </Space>
      )}
    </FlexContainer>
  </Cushion>
);

SocialLinks.defaultProps = {
  facebookPage: null,
  twitterAccount: null,
  instagramAccount: null,
};

SocialLinks.propTypes = {
  facebookPage: PropTypes.string,
  instagramAccount: PropTypes.string,
  twitterAccount: PropTypes.string,
};

export default SocialLinks;
