export default function bordersTheme({
  alto,
  dustygrey,
  dovegrey,
  mercury,
  primary,
  seashellgrey,
  white,
}) {
  return {
    mediumAltoDashed: `2px dashed ${alto}`,
    mediumDoveGrey: `2px solid ${dovegrey}`,
    mediumPrimary: `2px solid ${primary}`,
    thickMercury: `8px solid ${mercury}`,
    thickPrimary: `4px solid ${primary}`,
    thickTransparent: '8px solid transparent',
    thickWhite: `3px solid ${white}`,
    thinAlto: `2px solid ${alto}`,
    thinDarkGrey: '1px solid #D3D3D3',
    thinDoveGrey: `1px solid ${dustygrey}`,
    thinLightGrey: `1px solid ${seashellgrey}`,
    thinMercury: `1px solid ${mercury}`,
    thinPrimary: `1px solid ${primary}`,
  };
}
