import { ImageResizer } from '@pitchero/react-ui';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';

function getRetinaUrls({ backgroundColor, height, quality, src, transformation, width }) {
  const standardImageUrl = ImageResizer.resizeUrl(src, {
    bg: backgroundColor,
    h: height,
    w: width,
    t: transformation,
    q: quality,
  });

  const midImageUrl = ImageResizer.resizeUrl(src, {
    bg: backgroundColor,
    h: height * 1.5,
    w: width * 1.5,
    t: transformation,
    q: quality,
  });

  const retinaImageUrl = ImageResizer.resizeUrl(src, {
    bg: backgroundColor,
    h: height * 2,
    w: width * 2,
    t: transformation,
    q: quality,
  });

  return `${standardImageUrl} 1x, ${midImageUrl} 1.5x, ${retinaImageUrl} 2x`;
}

const loadingByPriority = {
  high: 'eager',
  low: 'lazy',
  auto: null,
};

const BasicImage = ({
  alt,
  backgroundColor,
  className,
  height,
  includeRetina,
  priority,
  quality,
  src: url,
  style,
  transformation,
  width,
}) => {
  const isUrgent = priority === 'high';
  const src = url
    ? ImageResizer.resizeUrl(url, {
        bg: backgroundColor,
        h: height,
        w: width,
        t: transformation,
        q: quality,
      })
    : null;
  const srcSet =
    includeRetina && src
      ? getRetinaUrls({
          src: url,
          backgroundColor,
          height,
          width,
          transformation,
          quality,
        })
      : null;

  return (
    <>
      {isUrgent && (
        <Head>
          <link
            rel="preload"
            as="image"
            // Fix: "Warning: React does not recognize the `fetchPriority` prop
            // on a DOM element. If you intentionally want it to appear in the
            // DOM as a custom attribute, spell it as lowercase `fetchpriority`
            // instead. If you accidentally passed it from a parent component,
            // remove it from the DOM element."
            // eslint-disable-next-line react/no-unknown-property
            fetchpriority={priority}
            href={src}
            // If you use `imgSrcSet` as suggested by ESLInt, at runtime you
            // will get "Warning: React does not recognize the `imageSrcSet`
            // prop on a DOM element. If you intentionally want it to appear in
            // the DOM as a custom attribute, spell it as lowercase
            // `imagesrcset` instead."
            // eslint-disable-next-line react/no-unknown-property
            imagesrcset={
              // there's a bug in either react or Next.js here, if we pass
              // `null` then `imagesrcset="null"` will be rendered. The
              // attribute is dropped correctly when `undefined` is passed.
              srcSet || undefined
            }
            key={`preload-${src}`}
          />
        </Head>
      )}
      <img
        alt={alt}
        className={className}
        height={height}
        src={src}
        // Disabling lint rule as using `fetchPriority` as ESLint asks, causes
        // this error in the browser:
        //
        // Warning: React does not recognize the `fetchPriority` prop on a DOM
        // element. If you intentionally want it to appear in the DOM as a
        // custom attribute, spell it as lowercase `fetchpriority` instead. If
        // you accidentally passed it from a parent component, remove it from
        // the DOM element. Error Component Stack
        //
        // eslint-disable-next-line react/no-unknown-property
        fetchpriority={priority}
        loading={loadingByPriority[priority]}
        decoding={isUrgent ? 'sync' : 'async'}
        srcSet={srcSet}
        style={{
          height: 'auto',
          maxWidth: '100%',
          ...style,
        }}
        width={width}
      />
    </>
  );
};

BasicImage.defaultProps = {
  backgroundColor: null,
  className: null,
  includeRetina: false,
  priority: 'auto',
  quality: null,
  transformation: 'fit',
  style: null,
};

BasicImage.propTypes = {
  alt: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number.isRequired,
  includeRetina: PropTypes.bool,
  priority: PropTypes.oneOf(['high', 'auto', 'low']),
  quality: PropTypes.number,
  src: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  transformation: PropTypes.string,
  width: PropTypes.number.isRequired,
};

export default BasicImage;
