import React from 'react';
import { Button, Cushion, Shadow, Space, Typography } from '@pitchero/react-ui';
import Head from 'next/head';
import Icon from '../icon';
import { Trans } from '../../lib/i18n';
import PitcheroLogo from '../pitchero-logo';

import { pitcheroDomain } from '../../lib/env';

const communityDomain = `https://${pitcheroDomain()}/community`;

const ClubNotFound = () => (
  <Cushion
    style={{ textAlign: 'center' }}
    top="xlarge"
    bottom="xxlarge"
    responsive={[{ minWidth: 'palm', props: { top: 'xxxlarge' } }]}
  >
    <Head>
      <title>Club not found</title>
    </Head>
    <Icon name="shield-important" color="mercury" size={95} />
    <Cushion bottom="medium" top="medium" horizontal="medium">
      <Typography component="div" preset="staticSubtitle--large">
        <Trans i18nKey="common:club_not_found">
          Oops, we can&apos;t find the club you&apos;re looking for
        </Trans>
      </Typography>
    </Cushion>
    <Cushion horizontal="medium">
      <Typography component="div" preset="body--large" color="dustygrey">
        <Trans i18nKey="common:check_url_or_search">
          Please check your URL or search for the club on Pitchero
        </Trans>
      </Typography>
    </Cushion>

    <Cushion component="div" bottom="xlarge" top="large">
      <a href={communityDomain} style={{ cursor: 'pointer' }}>
        <Shadow shadow="dark">
          <Button theme="secondary">
            <Trans i18nKey="common:search_club_cta">Search clubs</Trans>
          </Button>
        </Shadow>
      </a>
    </Cushion>
    <Space bottom="xsmall">
      <Cushion bottom="xsmall">
        <Typography color="black" preset="tag--large" component="div">
          <Trans i18nKey="common:powered_by">Powered by</Trans>
        </Typography>
      </Cushion>
      <PitcheroLogo />
    </Space>
  </Cushion>
);

export default ClubNotFound;
