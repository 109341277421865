import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import ClubRouter from '../../lib/routing';
import sanitizeParams from '../../lib/routing/sanitize-params';
import { getCurrentClub } from '../../store/clubs/selectors';
import AppInterceptedLink from '../app-intercepted-link';

function getPublicHref(currentRoute, clubRoute, currentClub, targetClub, hash) {
  const isCrossDomain = targetClub.externalDomain !== currentClub.externalDomain;
  const fragment = hash ? `#${hash}` : '';
  const pathname = `${clubRoute.as}${fragment}`;
  const freeBaseUrl = `/clubs/${targetClub.folder}`;
  const paidBaseUrl = '';
  const paidPathname = `${paidBaseUrl}${pathname}`;
  const freePathname = `${freeBaseUrl}${pathname}`;
  /** @example https://www.readinghockeyclub.org.uk/matches/#footer */
  const paidCrossDomainUrl = `https://${targetClub.externalDomain}${paidPathname}`;
  /** @example https://www.pitchero.com/clubs/readinghockeyclub/matches/#footer */
  const freeCrossDomainUrl = `https://www.pitchero.com${freePathname}`;

  if (isCrossDomain) {
    return targetClub.externalDomain ? paidCrossDomainUrl : freeCrossDomainUrl;
  }

  // Always stay on a development environment when developing
  if (process.env.NODE_ENV === 'development') {
    return freePathname;
  }

  // When viewing a free site:
  //   https://www.pitchero.com/clubs/readinghockeyclub
  //
  // We render the page with every link pointing to:
  //   https://www.readinghockeyclub.org.uk/
  //
  // @TODO: We could consider instead returning an HTTP permanent redirect when
  // a user navigates to a free plan URL for a paid site, to avoid content being
  // indexed on the free site and considered duplicate content.
  if (currentRoute.asPath.startsWith('/clubs/')) {
    return targetClub.externalDomain ? paidCrossDomainUrl : freePathname;
  }

  // Finally we are navigating internally within a paid site
  return paidPathname;
}

const ClubLink = ({
  route: routeName,
  params,
  club,
  currentClub,
  children,
  replace,
  scroll,
  hash,
  forceReload,
}) => {
  const router = useRouter();
  const cleanedParams = sanitizeParams(params);
  const route = ClubRouter.findAndGetUrls(routeName, cleanedParams).urls;
  const targetClub = club || currentClub;
  const as = getPublicHref(router, route, currentClub, targetClub, hash);

  // `<NextLink/>` is for client-side navigations internally within a single
  // app, which isn't going to work when we're switching clubs which could be on
  // other domains
  if (forceReload || targetClub.folder !== currentClub.folder) {
    return <a href={as}>{children}</a>;
  }

  const child = Children.only(children);
  const passHref = child.type === 'a' || child.type === AppInterceptedLink;
  return (
    <NextLink
      legacyBehavior
      prefetch={
        // Disable prefetching to favour reduced bandwidth usage over faster
        // subsequent navigations. Monitor the user experience and we can always
        // re-enable this if we find it's necessary.
        //
        // https://nextjs.org/docs/pages/api-reference/components/link#prefetch
        false
      }
      replace={replace}
      scroll={scroll}
      passHref={passHref}
      as={as}
      href={route.href}
    >
      {children}
    </NextLink>
  );
};

ClubLink.defaultProps = {
  params: {},
  club: null,
  replace: null,
  scroll: null,
  hash: null,
  forceReload: false,
};

ClubLink.propTypes = {
  club: PropTypes.shape({
    externalDomain: PropTypes.string,
    folder: PropTypes.string.isRequired,
  }),
  currentClub: PropTypes.shape({
    externalDomain: PropTypes.string,
    folder: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  route: PropTypes.string.isRequired,
  replace: PropTypes.bool,
  params: PropTypes.shape(),
  scroll: PropTypes.bool,
  hash: PropTypes.string,
  forceReload: PropTypes.bool,
};

const mapStateToProps = (state, { currentClub }) => ({
  currentClub: currentClub || getCurrentClub(state),
});

export default connect(mapStateToProps)(ClubLink);
