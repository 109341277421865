import React from 'react';
import { Cushion, Typography } from '@pitchero/react-ui';
import { map } from 'lodash';
import Icon from '../icon';
import ClubLink from '../club-link';
import getNavItems from '../club-frame/header/nav-items';
import { withTranslation, Trans } from '../../lib/i18n';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';

const ErrorLinks = ({ club = {} }) => {
  const navItems = getNavItems({ activeSection: false, club, limitTeams: true });
  return (
    <>
      {map(navItems, (navItem) => (
        <Cushion all="xsmall" component="div" key={navItem.id}>
          <ClubLink currentClub={club} {...navItem.routeParams}>
            <a>
              <Cushion all="small">
                <Typography
                  align="center"
                  color="primary"
                  preset="button"
                  component="div"
                  style={{ cursor: 'pointer' }}
                >
                  {navItem.transKey ? (
                    <Trans i18nKey={navItem.transKey}>{navItem.transKey}</Trans>
                  ) : (
                    navItem.name
                  )}
                  <Cushion left="xsmall">
                    <Icon
                      name="arrow-right"
                      size={14}
                      color="primary"
                      style={{ marginBottom: '-2px' }}
                    />
                  </Cushion>
                </Typography>
              </Cushion>
            </a>
          </ClubLink>
        </Cushion>
      ))}
    </>
  );
};

ErrorLinks.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
};

export default withTranslation(['common'])(ErrorLinks);
