import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../icon';

const SocialIcon = ({ iconName, url }) => (
  <a href={url} title={iconName} style={{ verticalAlign: 'middle' }}>
    <Icon
      alt={`Club ${iconName}`}
      color="primaryContrast"
      name={iconName}
      size={iconName === 'twitter' ? 16 : 20}
    />
  </a>
);

SocialIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default SocialIcon;
