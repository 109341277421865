import languages from './languages';

const NextI18Next = require('next-i18next').default;
const path = require('path');

const NextI18NextInstance = new NextI18Next({
  defaultLanguage: languages.defaultLanguageCode,
  fallbackLng: languages.defaultLanguageCode,
  localePath:
    typeof window === 'undefined'
      ? path.resolve('./club-static/static/locales')
      : '/static/locales',
  load: 'currentOnly',
  ignoreRoutes: ['/_next', '/static', '/data', '/logout', '/api'],
  otherLanguages: languages.otherLanguageCodes,
  localeSubpaths: {
    'en-US': 'en-US',
    'fi-FI': 'fi-FI',
  },
  serverLanguageDetection: false,
  react: {
    useSuspense: false,
  },
});

export const { appWithTranslation, config, i18n, Trans, withTranslation } = NextI18NextInstance;
