/* eslint-disable react/no-unknown-property */
import {
  Cushion,
  FlexContainer,
  Grid,
  LinearGradient,
  Rectangle,
  Shadow,
  Typography,
} from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Icon from '../../icon';
import { Trans } from '../../../lib/i18n';
import ClubLink from '../../club-link';

const SlashNavigation = ({ name, nameTransKey, activeSection, activeSectionTransKey, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Shadow shadow="dark">
      <Grid
        columns="auto 50px 1fr"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: '100%', backgroundColor: 'primaryDark' }}
        component="div"
      >
        <Rectangle fill="primaryDark">
          <Cushion left="medium" right="small" vertical="small">
            <Typography
              className="team-navigation__title"
              preset="title"
              color="primaryDarkContrast"
              size={16}
              lineHeight="1.5rem"
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              <Trans i18nKey={nameTransKey}>{name}</Trans>
            </Typography>
          </Cushion>
        </Rectangle>
        <LinearGradient
          angle={120}
          component="div"
          stops={[
            ['0%', 'primaryDark'],
            ['50%', 'primaryDark'],
            ['50%', '#fff'],
          ]}
          style={{ width: '100%', height: '100%' }}
        />
        <Rectangle fill="#fff" component="div">
          <Cushion all="small">
            <div>
              <Typography
                transform="capitalize"
                onClick={() => setIsOpen(!isOpen)}
                lineHeight="1.5rem"
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                <FlexContainer justifyContent="space-between" alignItems="center">
                  <Cushion right="small" component="span">
                    <Trans i18nKey={activeSectionTransKey}>{activeSection}</Trans>
                  </Cushion>
                  {items.length > 0 && (
                    <>
                      {isOpen ? (
                        <Icon name="arrow-up" size={16} />
                      ) : (
                        <Icon name="arrow-down" size={16} />
                      )}
                    </>
                  )}
                </FlexContainer>
              </Typography>
            </div>
          </Cushion>
        </Rectangle>
      </Grid>
      {items.length > 0 && isOpen && (
        <Rectangle fill="#fff">
          <Cushion all="medium">
            <FlexContainer flexDirection="column">
              {items.map((item) => {
                const isActive = item.name.toLowerCase() === activeSection.toLowerCase();
                return (
                  <ClubLink key={item.name} route={item.route} params={item.params}>
                    <button type="button" onClick={() => setIsOpen(false)}>
                      <Cushion vertical="xsmall" left="medium">
                        <Typography
                          component="div"
                          weight={isActive ? 'bold' : 'normal'}
                          align="left"
                          color="black"
                          transform="capitalize"
                        >
                          {isActive ? (
                            <div style={{ position: 'relative' }}>
                              <div
                                style={{
                                  content: ' ',
                                  width: '3px',
                                  height: '100%',
                                  backgroundColor: 'black',
                                  left: '-15px',
                                  bottom: '0',
                                  position: 'absolute',
                                  display: 'block',
                                }}
                              />
                              <Trans i18nKey={item.transKey}>{item.name}</Trans>
                            </div>
                          ) : (
                            <Trans i18nKey={item.transKey}>{item.name}</Trans>
                          )}
                        </Typography>
                      </Cushion>
                    </button>
                  </ClubLink>
                );
              })}
            </FlexContainer>
          </Cushion>
        </Rectangle>
      )}
    </Shadow>
  );
};

SlashNavigation.propTypes = {
  name: PropTypes.string.isRequired,
  nameTransKey: PropTypes.string,
  activeSection: PropTypes.string.isRequired,
  activeSectionTransKey: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      params: PropTypes.shape().isRequired,
      transKey: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

SlashNavigation.defaultProps = {
  nameTransKey: '',
};

export default SlashNavigation;
