import { Cushion, Grid, Rectangle, Typography } from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../icon';
import ClubLink from '../../../club-link';
import { Trans } from '../../../../lib/i18n';

const NavItem = ({
  isActive,
  groupedOptions,
  name,
  useSeparator,
  onClick,
  onClose,
  options,
  routeParams,
  transKey,
}) => {
  if (!transKey && !name) {
    return null;
  }

  const hasOptions = !!options || !!groupedOptions;

  const content = (
    <Rectangle fill="transparent" borderBottom={useSeparator ? 'thinDoveGrey' : 'none'}>
      <Cushion vertical="20px">
        <Grid columns="1fr auto" columnGap="medium" alignItems="center">
          <Typography
            preset="navItem--large"
            color="white"
            style={{
              textDecoration: isActive ? 'underline 4px' : 'none',
              textUnderlineOffset: '10px',
            }}
          >
            {transKey ? <Trans i18nKey={transKey}>{transKey}</Trans> : name}
          </Typography>
          {hasOptions && <Icon name="arrow-right" size={16} color="white" />}
        </Grid>
      </Cushion>
    </Rectangle>
  );

  if (hasOptions) {
    return (
      <div onClick={onClick} onKeyPress={onClick} role="presentation" style={{ cursor: 'pointer' }}>
        {content}
      </div>
    );
  }

  return (
    <ClubLink {...routeParams}>
      <a
        onClick={() => {
          onClose();
          return true;
        }}
        onKeyPress={() => {
          onClose();
          return true;
        }}
        role="presentation"
      >
        {content}
      </a>
    </ClubLink>
  );
};

NavItem.propTypes = {
  isActive: PropTypes.bool,
  groupedOptions: PropTypes.arrayOf(PropTypes.shape()),
  image: PropTypes.string,
  name: PropTypes.node,
  useSeparator: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  routeParams: PropTypes.shape(),
  transKey: PropTypes.string,
};
NavItem.defaultProps = {
  isActive: false,
  groupedOptions: null,
  image: null,
  name: null,
  useSeparator: false,
  onClick: () => null,
  onClose: () => null,
  options: null,
  routeParams: {},
  transKey: null,
};
export default NavItem;
