import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  Cushion,
  Input,
  MaxWidth,
  Rectangle,
  Shadow,
  Space,
  Typography,
} from '@pitchero/react-ui';

import Icon from '../icon';
import { attemptLogin, closeLoginPanel } from '../../store/auth/actions';
import {
  isAttemptingLogin,
  isLoggedIn,
  loginFailed,
  loginStatus,
} from '../../store/auth/selectors';
import { getCurrentClub } from '../../store/clubs/selectors';
import { appDomain, getVariable, pitcheroDomain } from '../../lib/env';
import { Trans, withTranslation } from '../../lib/i18n';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import ClubLink from '../club-link';
import LoadingBar from '../loading-bar';
import PitcheroLogo from '../pitchero-logo';
import TokenRedirect from '../token-redirect-link/route';

const LoginForm = ({
  attemptFailed,
  className,
  closePanel,
  closeMobilePanel,
  club,
  login,
  isLoggingIn,
  loggedIn,
  joinParams,
  style,
  titleI18nKey,
  titlePreset,
  status,
  isMobilePanel,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onKeyPressHandler = (event) => {
    const key = event.key || event.keyCode;
    if ((key === 'Enter' || key === 13) && event.shiftKey === false) {
      event.preventDefault();
      login(email, password);
    }
  };

  // Close the mobile panel 3 second after successful login
  useEffect(() => {
    if (loggedIn && closeMobilePanel) {
      setTimeout(() => {
        closeMobilePanel();
      }, 3000);
    }
  }, [loggedIn]);

  if (loggedIn) {
    return (
      <Cushion vertical="xlarge" style={style} className={className}>
        <Typography align="center" component="div">
          <LoadingBar isLoading={isLoggingIn} />
          <MaxWidth component="div" maxWidth={272} center>
            <Space bottom="large">
              <Typography preset="title" component="h2" color={isMobilePanel ? 'white' : ''}>
                <Trans i18nKey="common:login">Login</Trans>
              </Typography>
            </Space>
            <Typography align="center" component="div">
              <Shadow shadow="dark">
                <Rectangle
                  fill="positive"
                  radius="circle"
                  style={{
                    display: 'inline-block',
                    height: '66px',
                    width: '66px',
                    zIndex: 999,
                  }}
                  component="div"
                >
                  <Cushion all={18}>
                    <Icon name="tick" color="white" size={30} />
                  </Cushion>
                </Rectangle>
              </Shadow>
            </Typography>
            <Cushion top="medium">
              <Typography
                preset="subtitle--large"
                align="center"
                component="div"
                color="dustygrey"
                style={{ textTransform: 'none' }}
              >
                <Trans i18key="common:login_successful">Login successful</Trans>
              </Typography>
            </Cushion>
          </MaxWidth>
        </Typography>
      </Cushion>
    );
  }

  const buildFacebookUrl = () => {
    const clientId = getVariable('FACEBOOK_APP_ID');
    const redirectUri = `https://${pitcheroDomain()}/user-login/facebook-redirect`;
    let redirectUrl = `https://${appDomain()}/clubs/${club.folder}/token-login`;
    if (club.externalDomain) {
      redirectUrl = `https://${club.externalDomain}/token-login`;
    }

    const state = {
      redirect: redirectUrl,
    };

    return `https://www.facebook.com/v3.2/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&state=${JSON.stringify(
      state,
    )}&response_type=code`;
  };

  const loginErrorMessage = () => {
    if (!attemptFailed || status === null) {
      return false;
    }

    return (
      <Typography preset="subtitle--small" component="p" color="red">
        <Trans i18nKey={`common:login_error_${status}`}>
          Invalid email / password combination.
        </Trans>
      </Typography>
    );
  };

  return (
    <Typography align="center" component="div" className={className}>
      <LoadingBar isLoading={isLoggingIn} />
      <Cushion vertical="xlarge" component="div" style={style}>
        <MaxWidth component="div" maxWidth={272} center>
          <Space bottom="small">
            <Typography preset={titlePreset} component="h2" color={isMobilePanel ? 'white' : ''}>
              <Trans i18nKey={titleI18nKey}>Login</Trans>
            </Typography>
          </Space>

          <Space bottom="large">
            <Typography preset="body--small" component="div" color={isMobilePanel ? 'white' : ''}>
              <Trans i18nKey="common:login_register_note">Don’t have an account?</Trans>
              <Space left="xsmall">
                <Typography color={isMobilePanel ? 'white' : 'primary'} preset="subtitle--small">
                  <TokenRedirect route="join" params={joinParams}>
                    <Trans i18nKey="common:register">Register</Trans>
                  </TokenRedirect>
                </Typography>
              </Space>
            </Typography>
          </Space>

          <Space bottom="xsmall">
            <Typography
              preset="description"
              component="label"
              htmlFor={isMobilePanel ? 'usernameMobile' : 'username'}
              align="left"
              color={isMobilePanel ? 'white' : 'dustygrey'}
            >
              <Trans i18nKey="common:login_email_label">Email Address</Trans>
            </Typography>
          </Space>

          <Space bottom="medium">
            <Input
              className="login-email"
              type="email"
              id={isMobilePanel ? 'usernameMobile' : 'username'}
              name="username"
              disabled={isLoggingIn}
              input={{ value: email }}
              meta={{ touched: attemptFailed, error: attemptFailed ? ' ' : null }}
              onChange={(event) => setEmail(event.target.value)}
              onKeyDown={(e) => {
                onKeyPressHandler(e);
              }}
              style={{ fontSize: '16px' }}
            />
          </Space>

          <Space bottom="xsmall">
            <Typography
              preset="description"
              component="label"
              htmlFor={isMobilePanel ? 'passwordMobile' : 'password'}
              align="left"
              color={isMobilePanel ? 'white' : 'dustygrey'}
            >
              <Trans i18nKey="common:login_password_label">Password</Trans>
            </Typography>
          </Space>
          <Space bottom="medium" component="div">
            <Input
              className="login-password"
              type="password"
              id={isMobilePanel ? 'passwordMobile' : 'password'}
              name="password"
              disabled={isLoggingIn}
              input={{ value: password }}
              meta={{
                touched: attemptFailed,
                error: attemptFailed && status !== null,
              }}
              onChange={(event) => setPassword(event.target.value)}
              onKeyDown={(e) => {
                onKeyPressHandler(e);
              }}
              style={{ fontSize: '16px' }}
            />
            {loginErrorMessage()}
          </Space>

          <Space bottom="medium">
            <Button
              fullWidth
              className="login-submit"
              theme={isMobilePanel ? 'positive' : 'primary'}
              type="submit"
              disabled={isLoggingIn || email.length === 0 || password.length === 0}
              onClick={() => login(email, password)}
            >
              <Trans i18nKey="common:login">Login</Trans>
            </Button>
          </Space>

          <Space bottom="large">
            <Typography
              color={isMobilePanel ? 'white' : 'primary'}
              preset="subtitle--small"
              component="div"
              onClick={() => {
                if (closeMobilePanel) {
                  closeMobilePanel();
                }
                closePanel();
              }}
            >
              <ClubLink currentClub={club} route="password_reset">
                <a>
                  <Trans i18nKey="common:forgot_password_cta">Forgotten your password?</Trans>
                </a>
              </ClubLink>
            </Typography>
          </Space>

          <Space bottom="large" component="div">
            <Typography
              color={isMobilePanel ? 'white' : 'black'}
              preset="subtitle--small"
              component="a"
              href={buildFacebookUrl()}
            >
              <Space right="small">
                <Rectangle fill="facebook">
                  <Icon name="facebook" color="white" topOffset={3} />
                </Rectangle>
              </Space>
              <Trans i18nKey="common:facebook_login">Login with facebook</Trans>
            </Typography>
          </Space>

          <Space bottom="xsmall">
            <Typography
              color={isMobilePanel ? 'white' : 'black'}
              preset="tag--large"
              align="center"
              component="div"
            >
              <Trans i18nKey="common:powered_by">Powered by</Trans>
            </Typography>
          </Space>
          <PitcheroLogo color={isMobilePanel ? 'white' : 'black'} />
        </MaxWidth>
      </Cushion>
    </Typography>
  );
};

LoginForm.defaultProps = {
  className: null,
  joinParams: {},
  style: {},
  titleI18nKey: 'common:login',
  status: null,
  titlePreset: 'title',
  isMobilePanel: false,
  closeMobilePanel: () => null,
};

LoginForm.propTypes = {
  attemptFailed: PropTypes.bool.isRequired,
  className: PropTypes.string,
  closePanel: PropTypes.func.isRequired,
  closeMobilePanel: PropTypes.func,
  club: CLUB_PROP_TYPES.isRequired,
  login: PropTypes.func.isRequired,
  status: PropTypes.number,
  isLoggingIn: PropTypes.bool.isRequired,
  joinParams: PropTypes.shape(),
  loggedIn: PropTypes.bool.isRequired,
  style: PropTypes.shape(),
  titleI18nKey: PropTypes.string,
  titlePreset: PropTypes.string,
  isMobilePanel: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  club: getCurrentClub(state),
  loggedIn: isLoggedIn(state),
  attemptFailed: loginFailed(state),
  status: loginStatus(state),
  isLoggingIn: isAttemptingLogin(state),
});

export default connect(mapStateToProps, { login: attemptLogin, closePanel: closeLoginPanel })(
  withTranslation(['common'])(LoginForm),
);
