import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isLoggedIn } from '../../store/auth/selectors';
import { fetchNetworkPanelData } from '../../store/membership/actions';
import { getLoggedInUser, isLoadingNetworkPanel } from '../../store/membership/selectors';

const NetworkPanelDataLoader = ({ fetchNetworkPanel, isLoading, loggedIn, user }) => {
  useEffect(() => {
    // Attempt to get the network panel if we don't have a user but are logged in
    if (!user && loggedIn && !isLoading) {
      fetchNetworkPanel();
    }
  }, [user, loggedIn, isLoading]);

  return null;
};

NetworkPanelDataLoader.propTypes = {
  fetchNetworkPanel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatar: PropTypes.string,
  }),
};

NetworkPanelDataLoader.defaultProps = {
  user: null,
};

const dispatchToProps = {
  fetchNetworkPanel: fetchNetworkPanelData,
};

const mapStateToProps = (state) => ({
  loggedIn: isLoggedIn(state),
  isLoading: isLoadingNetworkPanel(state),
  user: getLoggedInUser(state),
});

export default connect(mapStateToProps, dispatchToProps)(NetworkPanelDataLoader);
